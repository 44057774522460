import React from 'react';
import './home.css';

const Home = () => (
  <div className="wrapper">
    <h1 className="header">Crafting playful interfaces from New York City.</h1>
    <h1 className="header">Now—running, egg-noodles, morning dew. </h1>
    <h1 className="header">(310)-806-2199</h1>
  </div>
);

export default Home;
